import './PriceItemExtra.scss';
import classNames from 'classnames';

const PriceItemExtra = (props) => {

    // Styles

    const priceClasses = classNames ('price-block-extra-price', {
        hidden: props.prevPrice === 0,
        hiddenPrev: props.prevPriceSecond === 0
    })

    return (
        <div className='price-item-extra'>
            <div className='price-item-extra-title-box'>
                <div className='price-block-extra-title'>
                    <h4>{props.name}</h4>
                    <h5>{props.addText}</h5>
                </div>
                
                <a href={props.payLink} target='_blank' rel='noreferrer'>
                    <button>
                        <span>Оплатити</span>
                    </button>
                </a>
            </div>

            <div className='price-block-extra-info-box'>
                <div className='price-block-extra-info'>
                    {
                        props.avlServices.map((text, index) => (
                            <span key={index}>{text}</span>
                        ))
                    }
                </div>

                <div className={priceClasses}>
                    <span>${props.price}</span>
                    <span>${props.prevPrice}</span>
                    <span>доступна оплата частинами</span>
                </div>
            </div>
        </div>
    )
}

export default PriceItemExtra
